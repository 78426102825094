import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import setupInterceptors from './services/setupInterceptors'
import Toast from "vue-toastification"

Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});

Vue.config.productionTip = false
    //setupInterceptors();
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')